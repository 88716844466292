import type { ComponentClass, FunctionComponent } from 'react';

import ErrorBoundary from './ErrorBoundary';

type ComponentFunction<TProps> =
  | FunctionComponent<TProps>
  | ComponentClass<TProps>;

const withErrorBoundary =
  <TProps extends {}>(
    Component: ComponentFunction<TProps>,
    componentName: string,
    metadata?: object,
  ) =>
  (props: TProps) =>
    (
      <ErrorBoundary componentName={componentName} metadata={metadata}>
        <Component {...props} />
      </ErrorBoundary>
    );

export default withErrorBoundary;
